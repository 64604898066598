import React from 'react';
import classnames from 'classnames';
import { Text, Icon } from '..';

import styles from './Alert.module.scss';

type AlertProps = {
  iconName?: string;
  title?: string;
  text?: string | React.ReactNode;
  customComponent?: React.ReactNode;
  customClass?: string;
};

function Alert({
  customClass,
  iconName = 'info',
  title,
  text = '',
  customComponent = '',
}: AlertProps) {
  const alertClass = classnames(styles.alert, {
    [`${customClass}`]: customClass,
  });

  return (
    <div className={alertClass}>
      <Icon name={iconName} size="medium" customClass={styles.icon} />
      <div className={styles.text_container}>
        {!!title && <Text text={title} customClass={styles.title_text} />}
        {!!text && <Text text={text} customClass={styles.text} />}
        {customComponent}
      </div>
    </div>
  );
}

export default Alert;
