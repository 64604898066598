import React, { useContext } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import { Stepper } from '../index';

import styles from './Wizard.module.scss';

import { WizardContext } from './Wizard';

/**
 * Wizard header Component
 */

function WizardHeader({
  id,
  showStepper,
  customClass,
  steps,
  children,
}: InferProps<typeof WizardHeader.propTypes>) {
  const wizardFather = useContext(WizardContext);

  const wizardHeaderClass = classnames(styles.bv_wizard_header, {
    [`${customClass}`]: customClass,
  });

  return (
    <div id={id} className={wizardHeaderClass}>
      {children}
      {showStepper && (
        <Stepper
          steps={steps}
          currentStep={wizardFather.props.currentStep}
          customClass={styles.bv_wizard_stepper}
        />
      )}
    </div>
  );
}

WizardHeader.propTypes = {
  id: PropTypes.string.isRequired,
  showStepper: PropTypes.bool.isRequired,
  steps: PropTypes.number.isRequired,
  customClass: PropTypes.string,
  children: PropTypes.any,
};

WizardHeader.defaultProps = {
  id: '',
  showStepper: true,
  steps: 0,
};

export default WizardHeader;
