import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import styles from './ModalNavegation.module.scss';

function ModalNavegationForm({
  children,
  customClass,
}: InferProps<typeof ModalNavegationForm.propTypes>) {
  const modalNavegationFormClass = classnames(styles.modal_navegation_form, {
    [`${customClass}`]: customClass,
  });

  return <div className={modalNavegationFormClass}>{children}</div>;
}

ModalNavegationForm.propTypes = {
  customClass: PropTypes.string,
  children: PropTypes.any,
};

export default ModalNavegationForm;
