import React, { useState, useEffect, Fragment } from 'react';
import classnames from 'classnames';

import { Modal } from '..';

import styles from './ModalFull.module.scss';

import { breakPointMd } from '../../utils/constants';
import { Container, Row, Col } from '../Container/Container';

function ModalFull({
  id,
  isOpen,
  onClose,
  getRef,
  asideChild,
  header,
  children,
  customClass,
  theme = 'primary',
}: ModalFullPropsT) {
  const modalFullClasses = classnames(styles.modal_full_container, {
    [styles[`modal_full_theme_${theme}`]]: theme,
    [`${customClass}`]: customClass,
  });

  const [closeIcon, setCloseIcon] = useState('default');

  const onResizeModal = () => {
    if (theme === 'primary') {
      setCloseIcon(window.innerWidth > breakPointMd ? 'default' : 'white');
    } else {
      setCloseIcon('default');
    }
  };

  useEffect(() => {
    if (!!asideChild) {
      window.addEventListener('resize', onResizeModal);
      onResizeModal();
    }

    return () => {
      if (!!asideChild) {
        window.removeEventListener('resize', onResizeModal);
      }
    };
  }, []);

  return (
    <Modal getRef={getRef} id={id} isOpen={isOpen} fullScreen closeIcon={closeIcon} close={onClose}>
      <Fragment>
        {header}
        <Container fluid className={modalFullClasses}>
          <Row justify="center" cols={1} className={styles.modal_full_row}>
            {asideChild ? (
              <Fragment>
                <Col sm={12} md={4} lg={4} xl={4} className={styles.modal_full_aside}>
                  <div className={styles.modal_full_aside_circle_top} />
                  <div className={styles.modal_full_aside_circle_bottom} />

                  {asideChild}
                </Col>
                <Col sm={12} md={8} lg={8} xl={8} className={styles.modal_full_body}>
                  {children}
                </Col>
              </Fragment>
            ) : (
              children
            )}
          </Row>
        </Container>
      </Fragment>
    </Modal>
  );
}

type ModalFullPropsT = {
  id: string;
  isOpen: boolean;
  onClose?: React.ComponentProps<typeof Modal>['close'];
  getRef: React.ComponentProps<typeof Modal>['getRef'];
  asideChild?: React.ReactNode;
  header?: React.ReactNode;
  children: React.ReactNode;
  customClass?: string;
  theme?: string;
};

ModalFull.defaultProps = {
  id: '',
  getRef: () => {},
};

export default ModalFull;
