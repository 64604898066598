import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Button } from '@BICE_Vida/components';
import classnames from 'classnames';

import styles from './CardList.module.scss';

function CardListBtn({ id, text, onClick, customClass }: InferProps<typeof CardListBtn.propTypes>) {
  const cardListBtnClass = classnames(styles.card_list_btn, {
    [`${customClass}`]: customClass,
  });

  return (
    <Button
      id={id}
      variant="outline"
      onClick={onClick}
      text={text}
      size="small"
      className={cardListBtnClass}
    />
  );
}

CardListBtn.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  customClass: PropTypes.string,
};

CardListBtn.defaultProps = {
  id: '',
};

export default CardListBtn;
