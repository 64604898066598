import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styles from './Form.module.scss';

function FormGroup({ children, hasError, className }: InferProps<typeof FormGroup.propTypes>) {
  const customClasses =
    !!className && typeof className !== 'string' ? className.join(' ') : className;

  const formClass: Array<any> = [styles.form_group, customClasses];

  if (hasError) {
    formClass.push(styles.form_group_error);
  }

  return <div className={formClass.join(' ')}>{children}</div>;
}

FormGroup.propTypes = {
  hasError: PropTypes.bool,
  children: PropTypes.element,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export function Form({ onSubmit, className, children }: InferProps<typeof Form.propTypes>) {
  const customClasses = !!className
    ? typeof className !== 'string'
      ? className.join(' ')
      : className
    : undefined;

  return (
    <form onSubmit={onSubmit} className={customClasses}>
      {children}
    </form>
  );
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.any,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default { Form, FormGroup };
