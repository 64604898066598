import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Button } from '@BICE_Vida/components';
import classnames from 'classnames';

import styles from './ModalNavegation.module.scss';

function ModalNavegationAction({
  id,
  click,
  disabled,
  text,
  customClass,
}: InferProps<typeof ModalNavegationAction.propTypes>) {
  const modalNavegationActionClass = classnames(styles.modal_navigation_action, {
    [`${customClass}`]: customClass,
  });

  return (
    <div className={modalNavegationActionClass}>
      <Button
        id={id}
        size="large"
        text={text}
        onClick={click}
        disabled={disabled}
        className={styles.modal_load_btn}
      />
    </div>
  );
}

ModalNavegationAction.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  click: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  customClass: PropTypes.string,
};

export default ModalNavegationAction;
