import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import { Icon } from '../index';

import styles from './Wizard.module.scss';

function WizardIconBack({ id, onClick, customClass }: InferProps<typeof WizardIconBack.propTypes>) {
  const wizardIconBackClass = classnames(styles.bv_wizard_btn_back_mobile_icon, {
    [`${customClass}`]: customClass,
  });

  return (
    <Icon
      id={id}
      name="arrow_back"
      size="medium"
      customClass={wizardIconBackClass}
      onClick={onClick}
    />
  );
}

WizardIconBack.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  customClass: PropTypes.string,
};

WizardIconBack.defaultProps = {
  id: '',
};

export default WizardIconBack;
