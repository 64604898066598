import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import classnames from 'classnames';

import styles from './Text.module.scss';

function Text({ text, customClass }: InferProps<typeof Text.propTypes>) {
  const textClasses = classnames(styles.text, {
    [`${customClass}`]: customClass,
  });

  return (
    <span className={textClasses}>
      {typeof text === 'string' ? ReactHtmlParser(text) : (text as any)}
    </span>
  );
}

Text.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  customClass: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
};

Text.defaultProps = {
  customClass: '',
  text: '',
};

export default Text;
