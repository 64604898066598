import React, { Fragment } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import { Text, Card, SvgImg } from '..';

import styles from './CardList.module.scss';

export function CardListGroup({
  id,
  children,
  customClass,
}: InferProps<typeof CardListGroup.propTypes>) {
  const customClasses = classnames(styles.card_list_group, {
    [`${customClass}`]: customClass,
  });

  return (
    <div id={id} className={customClasses}>
      {children}
    </div>
  );
}

CardListGroup.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.any,
  customClass: PropTypes.string,
};

CardListGroup.defaultProps = {
  id: '',
};

function CardList({
  id,
  title,
  description,
  image,
  actions,
  mobileActions,
  badges,
  customClass,
  selected,
  selectable,
  autoHideImage,
  autoShowSelector,
  onClick,
  disabled,
}: InferProps<typeof CardList.propTypes>) {
  const imageExt = image && image.split('.');
  const cardListClass = classnames(styles.card_list, {
    [`${customClass}`]: customClass,
  });
  const imageClasses = classnames(styles.card_list_image, {
    [`${styles.card_image_autohide}`]: !!autoHideImage && styles.card_image_autohide,
  });

  return (
    <Card
      id={id}
      bordered
      customClass={cardListClass}
      selected={selected}
      selector="check"
      selectable={selectable}
      autoShowSelector={autoShowSelector}
      onClick={onClick}
      disabled={disabled}
    >
      <Fragment>
        <div className={styles.card_list_container}>
          <div className={styles.card_list_information}>
            {image && (
              <div className={imageClasses}>
                {imageExt && !!imageExt[1] ? (
                  <img
                    src={image}
                    className={styles.card_list_image_img}
                    alt="bicevida card list img"
                  />
                ) : (
                  <SvgImg name={image as any} customClass={styles.card_list_image_img} />
                )}
              </div>
            )}
            <div className={styles.card_list_content}>
              <Text text={title} customClass={styles.card_list_title} />
              <Text text={description} customClass={styles.card_list_description} />
            </div>
          </div>
          {actions}
        </div>
        {badges && <div className={styles.card_list_badges}>{badges}</div>}
        {mobileActions}
      </Fragment>
    </Card>
  );
}

CardList.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  actions: PropTypes.node,
  mobileActions: PropTypes.node,
  badges: PropTypes.node,
  customClass: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  selectable: PropTypes.bool.isRequired,
  autoHideImage: PropTypes.bool.isRequired,
  autoShowSelector: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

CardList.defaultProps = {
  id: '',
  checked: false,
  disabled: false,
  selected: false,
  selectable: false,
  autoHideImage: false,
  autoShowSelector: false,
  onClick: () => {},
};

export default CardList;
