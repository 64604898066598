import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import { Text, Icon, IconAlt } from '..';

import styles from './ModalNavegation.module.scss';

function ModalNavegationHeader({
  headerText,
  steps,
  currentStep,
  onBack,
  onClose,
  customClass,
}: InferProps<typeof ModalNavegationHeader.propTypes>) {
  const modalHeaderClasses = classnames(styles.modal_navigation_header, {
    [`${customClass}`]: customClass,
  });

  const modalHeaderTitleClasses = classnames(styles.modal_navigation_header_title, {
    [styles.centered]: steps > 1 && currentStep > 1,
  });

  return (
    <div className={modalHeaderClasses}>
      {steps > 1 && currentStep > 1 && (
        <IconAlt title="Volver" position="bottom" customClass={styles.modal_navigation_icon_back}>
          <Icon name="arrow_back" size="medium" bgShape="circle" onClick={onBack} />
        </IconAlt>
      )}
      <Text text={headerText} customClass={modalHeaderTitleClasses} />
      {onClose && (
        <IconAlt
          title="Cerrar"
          position="bottom_right"
          customClass={styles.modal_navigation_icon_close}
        >
          <Icon name="close" size="medium" bgShape="circle" onClick={onClose} />
        </IconAlt>
      )}
    </div>
  );
}

ModalNavegationHeader.propTypes = {
  headerText: PropTypes.string.isRequired,
  steps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  customClass: PropTypes.string,
};

ModalNavegationHeader.defaultProps = {
  steps: 1,
  currentStep: 1,
};

export default ModalNavegationHeader;
