import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Button } from '@BICE_Vida/components';
import classnames from 'classnames';

import styles from './CardImg.module.scss';

function CardImg({
  id,
  customClass,
  img,
  text,
  textBody,
  link,
  textLink,
}: InferProps<typeof CardImg.propTypes>) {
  const classes = classnames(styles.container_card, {
    [`${customClass}`]: !!customClass,
  });

  const handleLinkClick = (url: string) => window.open(url, '_blank');

  return (
    <div className={classes}>
      <div className={styles.container_image}>
        <img src={img} className={styles.card_img} alt="bicevida imagen" />
      </div>
      <div className={styles.info_container}>
        <p className={styles.cardimg_text}>{text}</p>
        <p className={styles.cardimg_text_body}>{textBody}</p>
        <Button
          id={id || ''}
          text={textLink}
          variant="text"
          size="medium"
          onClick={() => handleLinkClick(link)}
          className={styles.button_link}
        />
      </div>
    </div>
  );
}

CardImg.propTypes = {
  id: PropTypes.string,
  customClass: PropTypes.string,
  img: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  textBody: PropTypes.string.isRequired,
  textLink: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default CardImg;
