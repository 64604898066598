import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Button } from '@BICE_Vida/components';
import classnames from 'classnames';

import styles from './ModalNavegation.module.scss';

function ModalNavegationRemove({
  cancel,
  remove,
  customClass,
}: InferProps<typeof ModalNavegationRemove.propTypes>) {
  const modalNavegationRemoveClass = classnames(styles.modal_navegation_remove, {
    [`${customClass}`]: customClass,
  });

  return (
    <div className={modalNavegationRemoveClass}>
      <Button
        id={cancel.id}
        text={cancel.text}
        onClick={cancel.click}
        variant="text"
        size="large"
      />
      <Button
        id={remove.id}
        text={remove.text}
        onClick={remove.click}
        variant="primary"
        size="large"
        className={styles.modal_navegation_btn_remove}
      />
    </div>
  );
}

ModalNavegationRemove.propTypes = {
  customClass: PropTypes.string,
  cancel: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    click: PropTypes.func.isRequired,
  }).isRequired,
  remove: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    click: PropTypes.func.isRequired,
  }).isRequired,
};

export default ModalNavegationRemove;
