import React, { Fragment } from 'react';
import classnames from 'classnames';

import Text from '../Text';

import styles from './ProgressBar.module.scss';

type ProgressBarCountType = {
  value: number;
  max?: number;
  customClass?: string;
  showText?: boolean;
  text?: string;
  customClassContainer?: string;
};

function ProgressBar({
  value,
  max = 100,
  customClass,
  showText = true,
  text,
  customClassContainer,
}: ProgressBarCountType) {
  const progressClass = classnames(styles.progress, {
    [`${customClass}`]: customClass,
  });

  const containerClass = classnames(styles.progress_container, {
    [`${customClassContainer}`]: customClassContainer,
  });

  return (
    <Fragment>
      <div className={containerClass}>
        <progress className={progressClass} value={value} max={max} />
        {showText && <Text text={text} customClass={styles.progress_text} />}
      </div>
    </Fragment>
  );
}

export default ProgressBar;
