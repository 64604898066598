import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import { Icon, IconAlt } from '..';

import styles from './CardList.module.scss';

function CardListBtn({
  id,
  variant,
  onClick,
  tooltipText,
  customClass,
}: InferProps<typeof CardListBtn.propTypes>) {
  const cardListIconClass = classnames(styles.card_list_icon, {
    [`${customClass}`]: customClass,
  });

  const icon = variant === 'edit' ? 'create' : variant === 'remove' ? 'delete' : 'add_circle';

  const iconComponent = (
    <Icon
      id={id}
      name={icon}
      size="medium"
      bgShape="circle"
      onClick={onClick}
      customClass={cardListIconClass}
    />
  );

  return tooltipText ? (
    <IconAlt title={tooltipText} position="top">
      {iconComponent}
    </IconAlt>
  ) : (
    iconComponent
  );
}

CardListBtn.propTypes = {
  id: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['edit', 'remove', 'add']).isRequired,
  onClick: PropTypes.func.isRequired,
  tooltipText: PropTypes.string,
  customClass: PropTypes.string,
};

CardListBtn.defaultProps = {
  id: '',
};

export default CardListBtn;
