import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import styles from './Badge.module.scss';

function Badge({
  text,
  icon,
  iconPosition,
  customClass,
  color,
  variant,
  size,
  textColor,
}: InferProps<typeof Badge.propTypes>) {
  const badgeClasses = classnames(styles.badge, {
    [styles[`badge_${size}`]]: size,
    [styles[`badge_${variant}`]]: variant,
    [`${customClass}`]: customClass,
    [styles[`badge_icon_${iconPosition}`]]: iconPosition && icon,
  });

  return (
    <div
      className={badgeClasses}
      style={{ backgroundColor: color ? `#${color}` : '', color: textColor ? `#${textColor}` : '' }}
    >
      {!!icon && typeof icon === 'string' && (
        <img src={`/icon-${icon}.svg`} className={styles.badge_icon} alt={`${icon} icon`} />
      )}
      {!!icon && typeof icon !== 'string' && icon}
      <span className={styles.badge_text}>{text}</span>
    </div>
  );
}

Badge.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  iconPosition: PropTypes.oneOf(['right', 'left', 'none']).isRequired,
  customClass: PropTypes.string.isRequired,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['colorized']).isRequired,
  size: PropTypes.oneOf(['small', 'medium']).isRequired,
  textColor: PropTypes.string,
};

Badge.defaultProps = {
  text: '',
  customClass: '',
  variant: 'colorized',
  size: 'medium',
  iconPosition: 'left',
  textColor: '',
};

export default Badge;
