import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import classnames from 'classnames';

import Text from '../Text';
import Icon from '../Icon';

import styles from './Tooltip.module.scss';

function Tooltip({
  icon,
  isShow,
  onClose,
  position,
  showIconClose,
  title,
  body,
  customClass,
  containerClass,
  action,
}: InferProps<typeof Tooltip.propTypes>) {
  const tooltipContainerClass = classnames(styles.tooltip, {
    [`${containerClass}`]: containerClass,
  });

  const tooltipClass = classnames(styles.tooltip_container, {
    [styles[`tooltip_${position}`]]: position,
    [`${customClass}`]: customClass,
  });

  return (
    <div className={tooltipContainerClass}>
      {icon}
      {isShow && (
        <div className={tooltipClass}>
          {showIconClose && (
            <Icon
              name="close"
              size="small"
              customClass={styles.tooltip_close_icon}
              onClick={onClose}
            />
          )}
          <Text text={title} customClass={styles.tooltip_title} />
          {body && <p className={styles.tooltip_body}>{ReactHtmlParser(body)}</p>}
          {action && <div className={styles.tooltip_action}>{action}</div>}
        </div>
      )}
    </div>
  );
}

Tooltip.propTypes = {
  icon: PropTypes.node.isRequired,
  isShow: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  position: PropTypes.oneOf(['top', 'top_right', 'bottom', 'bottom_right']).isRequired,
  showIconClose: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  containerClass: PropTypes.string,
  action: PropTypes.node,
};

Tooltip.defaultProps = {
  isShow: false,
  onClose: () => {},
  position: 'bottom',
  showIconClose: true,
};

export default Tooltip;
