import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import classnames from 'classnames';

import styles from './Label.module.scss';

function Label({ htmlFor, text, customClass }: InferProps<typeof Label.propTypes>) {
  const labelClass = classnames(styles.label, {
    [`${customClass}`]: !!customClass,
  });

  return (
    <label htmlFor={htmlFor || undefined} className={labelClass}>
      {text && ReactHtmlParser(text)}
    </label>
  );
}

Label.propTypes = {
  htmlFor: PropTypes.string,
  text: PropTypes.string,
  customClass: PropTypes.string,
};

export default Label;
