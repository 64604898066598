import React, { Fragment } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import Modal from '../Modal';
import ModalNavegationHeader from './ModalNavegationHeader';

import styles from './ModalNavegation.module.scss';

function ModalNavegation({
  id,
  isOpen,
  headerText,
  steps,
  currentStep,
  onBack,
  children,
  onClose,
}: InferProps<typeof ModalNavegation.propTypes>) {
  return (
    <Modal id={id} isOpen={isOpen} type="half_modal" modalClass={styles.modal_navigation}>
      <Fragment>
        <ModalNavegationHeader
          headerText={headerText}
          steps={steps}
          currentStep={currentStep}
          onBack={onBack}
          onClose={onClose}
          customClass={styles.modal_navigation_header_half_modal}
        />
        {children}
      </Fragment>
    </Modal>
  );
}

ModalNavegation.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  headerText: PropTypes.string.isRequired,
  steps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  onBack: PropTypes.func,
  children: PropTypes.any,
  onClose: PropTypes.func,
};

ModalNavegation.defaultProps = {
  id: '',
  isOpen: false,
  steps: 1,
  currentStep: 1,
};

export default ModalNavegation;
