import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styles from './Toggle.module.scss';

function Toggle({
  id,
  leftOptionText,
  rightOptionText,
  leftOptionValue,
  rightOptionValue,
  checked,
  onClick,
}: InferProps<typeof Toggle.propTypes>) {
  const toggleClick = (e: any) => {
    const value = !checked
      ? rightOptionValue || rightOptionText || 'true'
      : leftOptionValue || leftOptionText || 'false';

    onClick(!checked, value);
    e.preventDefault();
  };

  return (
    <label id={id} className={styles.toggle} onClick={toggleClick} aria-hidden="true">
      <input type="checkbox" checked={checked} onChange={() => {}} />
      <div className={styles.toggle_slider_container}>
        <span className={styles.toggle_slider} />
        <span className={styles.toggle_left_opt}>{leftOptionText}</span>
        <span className={styles.toggle_right_opt}>{rightOptionText}</span>
      </div>
    </label>
  );
}

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  leftOptionText: PropTypes.string,
  rightOptionText: PropTypes.string,
  leftOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rightOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

Toggle.defaultProps = {
  id: '',
  checked: false,
  onClick: () => {},
};

export default Toggle;
