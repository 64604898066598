import React, { useState, Fragment, useRef, useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import { Icon } from '..';

import styles from './Select.module.scss';

import {
  footerHeightSize,
  optionsHeightSize,
  spacingHeightSize,
  maxHeightSize,
  separatorSplit,
} from '../../utils/constants';

export function SelectGroup({
  placeholder,
  showOptions,
  visited,
  feedback,
  selected,
  disabled,
  icon,
  onClick,
  selectRef,
  customClass,
}: InferProps<typeof SelectGroup.propTypes>) {
  const selectBaseClass: string[] = [styles.select];

  customClass && selectBaseClass.push(customClass);

  const selectClass = classnames(styles.select, {
    [styles.select_disabled]: disabled,
    [styles.select_error]: visited && !showOptions && selected.length === 0 && !disabled,
    [styles.select_open]: showOptions,
  });

  const placeholderClass = showOptions
    ? styles.floating_label_open
    : selected.length > 0
    ? styles.floating_label_selected
    : styles.floating_label;

  const showFeedback = feedback && visited && !showOptions && selected.length === 0 && !disabled;

  const selectedDouble = selected.split(separatorSplit);
  const isMoreLine = selectedDouble.length > 1;

  return (
    <Fragment>
      {!!selectRef && (
        <div ref={selectRef} className={selectClass} onClick={onClick}>
          <label>{selectedDouble[0]}</label>
          <label className={styles.text_second_line}>{isMoreLine && selectedDouble[1]}</label>
        </div>
      )}

      <span className={placeholderClass}>{placeholder}</span>
      {showFeedback && <span className={styles.select_feedback}>{feedback}</span>}
      <Icon name={icon.name} customClass={icon.class} size="medium" />
    </Fragment>
  );
}

SelectGroup.propTypes = {
  placeholder: PropTypes.string.isRequired,
  feedback: PropTypes.string,
  selected: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  showOptions: PropTypes.bool.isRequired,
  visited: PropTypes.bool,
  customClass: PropTypes.string,
  icon: PropTypes.shape({
    name: PropTypes.string,
    class: PropTypes.string,
  }).isRequired,
  selectRef: PropTypes.any,
  disabled: PropTypes.bool.isRequired,
};

SelectGroup.defaultProps = {
  id: '',
  direction: 'row',
  icon: {
    name: 'expand_less',
    class: styles.icon_open,
  },
  onClick: () => {},
  disabled: false,
};

function Select({
  id,
  placeholder,
  options,
  customClass,
  feedback,
  value,
  onChange,
  onClick,
  disabledOut,
  restartValue,
  doubleOption,
}: InferProps<typeof Select.propTypes>) {
  const selectRef = useRef<HTMLDivElement>(null);
  const selectMobileRef = useRef<HTMLSelectElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);

  const containerClass = classnames(styles.select_container, {
    [`${customClass}`]: customClass,
    [styles.select_container_disabled]: disabledOut,
  });

  const [selected, setSelected] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [visited, setVisited] = useState(false);
  const [optionsUp, setOptionsUp] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const selectClass = classnames(styles.select_menu, {
    [styles.select_up]: !optionsUp,
  });

  const [icon, setIcon] = useState({
    name: 'expand_less',
    class: styles.icon_open,
  });

  const isDoubleOptions = doubleOption && doubleOption.length > 0;

  useEffect(() => {
    setIsMobile(!navigator.userAgent.match(/iPhone|iPad|Android/));

    document.addEventListener('click', handleClickOutside, false);

    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    if (showOptions) {
      const bottomSelect = selectRef.current?.getBoundingClientRect().bottom;
      const lengthOptions = isDoubleOptions ? doubleOption?.length : options.length;

      const HeightMenu =
        lengthOptions && lengthOptions * optionsHeightSize < maxHeightSize
          ? lengthOptions * optionsHeightSize
          : maxHeightSize;

      const totalSize = HeightMenu + spacingHeightSize;
      setOptionsUp(window.innerHeight - footerHeightSize - bottomSelect! > totalSize);
      setIcon({
        name: 'expand_less',
        class: styles.icon_open,
      });
    } else {
      setIcon({
        name: 'expand_more',
        class: styles.icon,
      });
      selected.length === 0 && visited && onClick && onClick(selected);
    }
  }, [showOptions]);

  useEffect(() => {
    value && setSelected(value);
  }, [value]);

  useEffect(() => {
    if (restartValue) {
      setSelected('');
    }
  }, [restartValue]);

  useEffect(() => {
    selectMobileRef.current?.blur();
    setShowOptions(false);
  }, [selected]);

  const handleClickOutside = async (event: any) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setShowOptions(false);
    }
    if (selectMobileRef.current && !selectMobileRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };

  const toggleList = async () => {
    setShowOptions(!showOptions);
    setVisited(true);
  };

  const toggleListMobile = async () => {
    setShowOptions(!showOptions);
  };

  const handleClick = async (value: any) => {
    setSelected(value);
    onChange && onChange(value);
  };

  const showFeedbackSingle = visited && !showOptions && selected.length === 0;
  const showFeedbackDouble = selected === '';

  return (
    <Fragment>
      {isMobile ? (
        <div id={id} className={containerClass}>
          <SelectGroup
            feedback={feedback}
            onClick={toggleList}
            showOptions={showOptions}
            placeholder={placeholder}
            selected={selected}
            visited={visited}
            icon={icon}
            selectRef={selectRef}
            customClass={customClass}
            disabled={disabledOut}
          />
          {showOptions && (
            <div ref={optionsRef} className={selectClass}>
              {!isDoubleOptions &&
                options.map((value: any, key: number) => (
                  <div
                    id={`option_${value}`}
                    onClick={() => handleClick(value)}
                    className={styles.option}
                    key={`option_${key}_${value}`}
                  >
                    <label htmlFor={value} className={styles.options_text}>
                      {value}
                    </label>
                  </div>
                ))}
              {doubleOption &&
                doubleOption.map((value: any, key: number) => (
                  <div
                    id={`option_${value.name}`}
                    onClick={() => handleClick(value.name + separatorSplit + value.rut)}
                    className={styles.option}
                    key={`option_${key}_${value.name}`}
                  >
                    <label htmlFor={value.name} className={styles.options_text_primary}>
                      {value.name}
                    </label>
                    <label htmlFor={value.rut} className={styles.text_second_line}>
                      {value.rut}
                    </label>
                  </div>
                ))}
            </div>
          )}
        </div>
      ) : (
        <div id={id} className={containerClass}>
          <SelectGroup
            feedback={feedback}
            showOptions={showOptions}
            placeholder={placeholder}
            selected={selected}
            visited={visited}
            icon={icon}
            customClass={customClass}
            disabled={disabledOut}
          />
          <select
            ref={selectMobileRef}
            className={`${styles.select} ${disabledOut ? styles.select_disabled : ''}`}
            name={placeholder}
            disabled={disabledOut}
            required
            onChange={(event: any) => {
              handleClick(event.target.value);
            }}
            value={selected}
            onClick={toggleListMobile}
          >
            <option className={styles.mobile} />
            {!isDoubleOptions &&
              options.map((value: any, key: number) => (
                <option id={`option_${value}`} value={value} key={`option_${key}_${value}`}>
                  {value}
                </option>
              ))}
            {!isDoubleOptions && showFeedbackSingle && (
              <span className={styles.select_feedback}>{feedback}</span>
            )}

            {isDoubleOptions &&
              doubleOption &&
              doubleOption.map((value: any, key: number) => (
                <option
                  id={`option_${value.name}`}
                  value={`${value.name}${separatorSplit}${value.rut}`}
                  key={`option_${key}_${value.name}`}
                >
                  {value.name}
                </option>
              ))}
          </select>
          {isDoubleOptions && doubleOption && showFeedbackDouble && (
            <span className={styles.select_feedback}>{feedback}</span>
          )}
        </div>
      )}
    </Fragment>
  );
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  customClass: PropTypes.string,
  feedback: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  disabledOut: PropTypes.bool.isRequired,
  restartValue: PropTypes.bool,
  doubleOption: PropTypes.arrayOf(PropTypes.object),
};

Select.defaultProps = {
  options: [''],
  placeholder: '',
  disabledOut: false,
  restartValue: false,
};

export default Select;
