import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import styles from './CardList.module.scss';

function CardListActions({
  iconEdit,
  iconAdd,
  iconRemove,
  btn,
  check,
  customClass,
}: InferProps<typeof CardListActions.propTypes>) {
  const cardListActionClasses = classnames(styles.card_list_actions, {
    [`${customClass}`]: customClass,
  });

  return (
    <div className={cardListActionClasses}>
      {iconAdd}
      {iconRemove}
      {iconEdit}
      {btn}
      {check}
    </div>
  );
}

CardListActions.propTypes = {
  iconEdit: PropTypes.node,
  iconRemove: PropTypes.node,
  iconAdd: PropTypes.node,
  btn: PropTypes.node,
  check: PropTypes.node,
  customClass: PropTypes.string,
};

CardListActions.defaultProps = {
  customClass: '',
};

export default CardListActions;
