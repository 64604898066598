import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import styles from './Stepper.module.scss';

function Stepper({ id, steps, currentStep, customClass }: InferProps<typeof Stepper.propTypes>) {
  const stepperClass: string[] = [styles.bv_stepper];

  !!customClass && stepperClass.push(customClass);

  const stepsComponents = [];

  for (let i = 0; i < steps; i++) {
    const stepClass = [styles.bv_stepper_step];

    i + 1 < currentStep && stepClass.push(styles.bv_stepper_step_passed);
    i + 1 === currentStep && stepClass.push(styles.bv_stepper_step_active);

    stepsComponents.push(<div key={`bv_stepper_step_${i}`} className={stepClass.join(' ')} />);
  }

  return (
    <div id={id} className={stepperClass.join(' ')}>
      {stepsComponents}
    </div>
  );
}

Stepper.propTypes = {
  id: PropTypes.string.isRequired,
  steps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  customClass: PropTypes.string,
};

Stepper.defaultProps = {
  id: '',
};

export default Stepper;
