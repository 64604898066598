import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import styles from './Wizard.module.scss';

function WizardBodyView({
  id,
  customClass,
  children,
}: InferProps<typeof WizardBodyView.propTypes>) {
  const wizardBodyClass = classnames(styles.bv_wizard_body_view, {
    [`${customClass}`]: customClass,
  });

  return (
    <div id={id} className={wizardBodyClass}>
      {children}
    </div>
  );
}

WizardBodyView.propTypes = {
  id: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  children: PropTypes.any,
};

WizardBodyView.defaultProps = {
  id: '',
};

export default WizardBodyView;
