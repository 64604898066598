import React, { useRef, useState } from 'react';
import classnames from 'classnames';

import styles from './IconAlt.module.scss';

type IconAltT = {
  id?: string;
  title?: string;
  position: 'top' | 'bottom' | 'right' | 'left' | 'bottom_right';
  customClass?: string;
  children: JSX.Element;
};

function IconAlt({ id, title, position, customClass, children }: IconAltT) {
  const node = useRef<HTMLDivElement>(null);
  const [isVisible, setState] = useState(false);

  const iconAltClass = classnames(styles.container, {
    [`${customClass}`]: customClass,
  });

  const handleMouseAction = () => setState(!isVisible);

  return (
    <div ref={node} className={iconAltClass} id={id}>
      <div
        className={styles.tooltip_icon}
        onPointerOver={handleMouseAction}
        onPointerOut={handleMouseAction}
      >
        {children}
      </div>
      {isVisible && (
        <div className={styles.tooltip_responsive}>
          {!!title && (
            <div className={`${styles.tooltip_content} ${styles[position.toString()]}`}>
              <span className={styles.arrow} />
              {title}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default IconAlt;
