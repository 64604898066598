import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import Badge from '../../components/Badge';
import { Icon, IconAlt } from '..';

import styles from './CardList.module.scss';

function CardListBadge({
  text,
  tooltipText,
  onRemove,
}: InferProps<typeof CardListBadge.propTypes>) {
  const iconComponent = (
    <Icon name="close" customClass={styles.card_list_badge_icon} onClick={onRemove} />
  );

  const iconFinalComponent = tooltipText ? (
    <IconAlt title={tooltipText} position="top" customClass={styles.card_list_badge_icon_alt}>
      {iconComponent}
    </IconAlt>
  ) : (
    iconComponent
  );

  return (
    <Badge
      text={text}
      color="000000"
      customClass={styles.card_list_badge}
      icon={onRemove && iconFinalComponent}
      iconPosition="right"
    />
  );
}

CardListBadge.propTypes = {
  text: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  tooltipText: PropTypes.string,
};

export default CardListBadge;
