import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import classnames from 'classnames';
import { Button } from '@BICE_Vida/components';

import styles from './Card.module.scss';

const defaultFunc = () => {};

function CardRadio({ name, selected }: InferProps<typeof CardRadio.propTypes>) {
  const cardSelectorClass = classnames(styles.card_selector_custom, styles.card_radio_custom);

  return (
    <label className={styles.card_selectable}>
      <input
        type="radio"
        className={styles.card_selector}
        name={name}
        disabled
        checked={selected}
      />
      <span className={cardSelectorClass} />
    </label>
  );
}

CardRadio.propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

CardRadio.defaultProps = {
  name: 'card',
  selected: false,
};

function CardCheck({ selected, autoShow }: InferProps<typeof CardCheck.propTypes>) {
  const customClasses = classnames(styles.card_selectable, {
    [`${styles.card_selector_autoshow}`]: !!autoShow && styles.card_selector_autoshow,
  });

  const selectorClasses = classnames(styles.card_selector_custom, styles.card_check_custom);

  return (
    <label className={customClasses}>
      <input type="checkbox" className={styles.card_selector} disabled checked={selected} />
      <span className={selectorClasses} />
    </label>
  );
}

CardCheck.propTypes = {
  selected: PropTypes.bool.isRequired,
  autoShow: PropTypes.bool,
};

CardCheck.defaultProps = {
  selected: false,
  autoShow: false,
};

function Card({
  id,
  title,
  text,
  children,
  customClass,
  icon,
  iconPosition,
  bordered,
  selectable,
  onClick,
  name,
  selected,
  btnAction,
  btnActionClass,
  selector,
  autoShowSelector,
  disabled,
}: InferProps<typeof Card.propTypes>) {
  const cardClass = classnames(styles.card, {
    [`${customClass}`]: !!customClass,
    [styles[`card_icon_${iconPosition}`]]: !!icon && !!iconPosition,
    [styles.bordered]: !!bordered,
    [styles.selectable]: !!selectable,
    [styles.selectable_with_selector]: !!selectable && selector !== 'default',
    [styles.selected]: !!selected,
    [styles.disabled]: !!disabled,
    [styles.actions]: !!btnAction,
  });

  const clickFunc = (!!selectable && onClick) || defaultFunc;

  return (
    <div id={id} className={cardClass} onClick={clickFunc}>
      {selectable && selector === 'radio' && <CardRadio name={name} selected={selected} />}
      {selectable && selector === 'check' && (
        <CardCheck selected={selected} autoShow={autoShowSelector} />
      )}
      <div className={styles.card_container}>
        {!!icon && <div className={styles.card_icon}>{icon}</div>}
        <div className={styles.card_content}>
          {!!title && (
            <div
              className={
                !!title && (!!children || !!text) ? styles.card_title_container : undefined
              }
            >
              <span className={styles.card_title}>{title}</span>
            </div>
          )}
          <div className={styles.card_body}>
            {children || (
              <span className={styles.card_text}>
                {typeof text === 'string' ? ReactHtmlParser(text) : text}
              </span>
            )}
          </div>
        </div>
      </div>
      {!!btnAction && (
        <div className={styles.card_actions_container}>
          <Button
            id={btnAction.id}
            text={btnAction.text}
            variant="text"
            onClick={(event: any) => {
              event.stopPropagation();
              btnAction.click();
            }}
            size="medium"
            className={btnActionClass}
          />
        </div>
      )}
    </div>
  );
}

Card.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.element,
  customClass: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
    .isRequired,
  icon: PropTypes.element,
  bordered: PropTypes.bool.isRequired,
  iconPosition: PropTypes.oneOf(['left', 'right', 'top', 'bottom', 'top_center']),
  selectable: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  btnAction: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    click: PropTypes.func.isRequired,
  }),
  btnActionClass: PropTypes.string.isRequired,
  selector: PropTypes.oneOf(['radio', 'check', 'default']).isRequired,
  autoShowSelector: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
};

Card.defaultProps = {
  id: '',
  customClass: '',
  btnActionClass: '',
  iconPosition: 'left',
  bordered: false,
  selectable: false,
  onClick: defaultFunc,
  name: 'card',
  selected: false,
  selector: 'default',
  autoShowSelector: false,
  disabled: false,
};

export default Card;
