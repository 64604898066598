import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styles from './Icon.module.scss';

export function IconBg({
  children,
  shape,
  customClass,
  size,
}: InferProps<typeof IconBg.propTypes>) {
  const bgClass = [];

  !!customClass && bgClass.push(customClass);
  !!shape && bgClass.push(styles[`icon_bg_${shape}`]);
  !!size && bgClass.push(styles[`icon_bg_${size}`]);

  return <span className={bgClass.join(' ')}>{children}</span>;
}

IconBg.propTypes = {
  children: PropTypes.element.isRequired,
  shape: PropTypes.oneOf(['circle']).isRequired,
  customClass: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
};

IconBg.defaultProps = {
  shape: 'circle',
};

function Icon({
  id,
  name,
  size,
  customClass,
  onClick,
  bgShape,
}: InferProps<typeof Icon.propTypes>) {
  const customClasses =
    !!customClass && typeof customClass !== 'string' ? customClass.join(' ') : customClass;
  const iconClass: string[] = [styles.icon_material_round];

  !!size && iconClass.push(styles[`icon_${size}`]);
  !!customClasses && iconClass.push(customClasses);
  !!onClick && iconClass.push(styles.icon_click);

  const component = (
    <i id={id} className={iconClass.join(' ')} onClick={onClick || undefined}>
      {name}
    </i>
  );

  return !!bgShape ? (
    <IconBg shape={bgShape} size={size}>
      {component}
    </IconBg>
  ) : (
    component
  );
}

Icon.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  customClass: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onClick: PropTypes.func,
  bgShape: PropTypes.oneOf(['circle']),
};

Icon.defaultProps = {
  id: '',
};

export default Icon;
