import React, { useState, useEffect, useRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import Icon from '../Icon';

import styles from './Toaster.module.scss';

import { delayToaster } from '../../utils/constants';

function Toaster({
  id,
  show,
  centered,
  timeout,
  setHideToaster,
  text,
  icon,
  iconPosition,
  customClass,
}: InferProps<typeof Toaster.propTypes>) {
  const [showToaster, setShowToaster] = useState(false);
  const toasterTimeoutRef = useRef<any>(null);

  const toasterClasses = classnames(styles.toaster, {
    [`${customClass}`]: customClass,
    [styles[`toaster_icon_${iconPosition}`]]: icon,
    [styles.centered]: centered,
    [styles.show]: showToaster,
  });

  useEffect(() => {
    setShowToaster(show);

    if (show) {
      toasterTimeoutRef.current = setTimeout(() => {
        setHideToaster();
      }, timeout);
    } else {
      clearTimeout(toasterTimeoutRef.current);
    }
  }, [show]);

  useEffect(() => {
    return () => {
      clearTimeout(toasterTimeoutRef.current);
    };
  }, []);

  return (
    <span id={id} className={toasterClasses}>
      {icon && <Icon name={icon} size="small" customClass={styles.toaster_icon} />}
      {text}
    </span>
  );
}

Toaster.propTypes = {
  id: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  /**
   * En milisegundos
   */
  timeout: PropTypes.number.isRequired,
  setHideToaster: PropTypes.func.isRequired,
  centered: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']).isRequired,
  customClass: PropTypes.string,
};

Toaster.defaultProps = {
  id: '',
  centered: true,
  show: false,
  timeout: delayToaster,
  iconPosition: 'left',
};

export default Toaster;
