import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import styles from './ModalNavegation.module.scss';

function ModalNavegationContent({
  id,
  customClass,
  children,
}: InferProps<typeof ModalNavegationContent.propTypes>) {
  const modalNavegationBodyClass = classnames(styles.modal_navigation_body, {
    [`${customClass}`]: customClass,
  });

  return (
    <div id={id} className={modalNavegationBodyClass}>
      {children}
    </div>
  );
}

ModalNavegationContent.propTypes = {
  id: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  children: PropTypes.any,
};

ModalNavegationContent.defaultProps = {
  id: '',
};

export default ModalNavegationContent;
