import React, { useState, useEffect, Fragment } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import { Icon, IconAlt } from '../../designSystem';

import styles from './Accordion.module.scss';

import { textAltIcon } from '../../utils/generalTexts';

function Accordion({
  title,
  titleClass,
  titleContainerClass,
  highlight,
  children,
  outline,
  stayOpen,
}: InferProps<typeof Accordion.propTypes>) {
  const [isCollapsed, setIsCollapsed] = useState(stayOpen);
  const [bodyClass, setBodyClass] = useState([styles.body_container, styles.hide]);
  const [icon, setIcon] = useState('expand_more');
  const [msgAlt, setMsgAlt] = useState(textAltIcon.seeMore);
  const accordionClick = () => setIsCollapsed(!isCollapsed);

  useEffect(() => {
    if (isCollapsed) {
      setBodyClass([styles.body_container]);

      setBodyClass([styles.body_container, styles.show]);
      setIcon('expand_less');
      setMsgAlt(textAltIcon.seeLess);
    } else {
      setBodyClass([styles.body_container, styles.hide]);
      setIcon('expand_more');
      setMsgAlt(textAltIcon.seeMore);
    }
  }, [isCollapsed]);

  const accordionClass = classnames(styles.title_container, {
    [`${titleContainerClass}`]: titleContainerClass,
    [styles.accordion_outline_title_container]: outline,
  });

  const titleTextClass = classnames(styles.accordion_title, {
    [`${titleClass}`]: titleClass,
  });

  return (
    <Fragment>
      <div className={accordionClass} onClick={accordionClick}>
        <span className={titleTextClass}>
          {title} {!!highlight && <span className={styles.highlight}>{highlight}</span>}
        </span>
        <IconAlt title={msgAlt} position="bottom">
          <Icon name={icon} size="medium" customClass={styles.title_icon} bgShape="circle" />
        </IconAlt>
      </div>
      <div className={bodyClass.join(' ')}>{children}</div>
    </Fragment>
  );
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  titleClass: PropTypes.string.isRequired,
  titleContainerClass: PropTypes.string.isRequired,
  highlight: PropTypes.string,
  children: PropTypes.element.isRequired,
  outline: PropTypes.bool.isRequired,
  stayOpen: PropTypes.bool,
};

Accordion.defaultProps = {
  title: '',
  titleClass: '',
  titleContainerClass: '',
  childrenClass: '',
  outline: false,
  stayOpen: false,
};

export default Accordion;
