import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import Card from '../Card/Card';
import { RadioButton } from '../RadioButton';

import styles from './CardRadioButton.module.scss';

export function CardRadioButtonGroup({
  id,
  children,
  customClass,
}: InferProps<typeof CardRadioButtonGroup.propTypes>) {
  const cardRadioButtonGroupClass = classnames(styles.card_radio_button_group, {
    [`${customClass}`]: customClass,
  });

  return (
    <div id={id} className={cardRadioButtonGroupClass}>
      {children}
    </div>
  );
}

CardRadioButtonGroup.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.any,
  customClass: PropTypes.string,
};

CardRadioButtonGroup.defaultProps = {
  id: '',
};

function CardRadioButton({
  id,
  name,
  value,
  text,
  checked,
  onChange,
  customClass,
}: InferProps<typeof CardRadioButton.propTypes>) {
  const cardRadioButtonClass = classnames(styles.card_radio_button, {
    [`${customClass}`]: customClass,
  });

  const onChangeHandler = (newValue: any) => {
    onChange && onChange(newValue);
  };

  return (
    <Card
      id={id}
      bordered
      customClass={cardRadioButtonClass}
      selectable
      onClick={() => onChangeHandler(value)}
      selected={checked}
    >
      <RadioButton
        name={name}
        value={value}
        onChange={onChangeHandler}
        text={text ?? undefined}
        customClass={styles.card_radio_button_radio}
        checked={checked}
      />
    </Card>
  );
}

CardRadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  text: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  customClass: PropTypes.string,
};

CardRadioButton.defaultProps = {
  id: '',
  checked: false,
  disabled: false,
};

export default CardRadioButton;
