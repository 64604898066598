import React, { createContext, useRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import styles from './Wizard.module.scss';

export { default as WizardHeader } from './WizardHeader';
export { default as WizardBodyView } from './WizardBodyView';
export { default as WizardFooter } from './WizardFooter';

export const WizardContext = createContext({
  props: { currentStep: 0, showBackBtnAlways: false },
  scrollToTop: () => {},
});

function Wizard(props: InferProps<typeof Wizard.propTypes>) {
  const {
    id,
    showBackBtnAlways,
    currentStep,
    wizardHeader,
    wizardFooter,
    iconBack,
    customClass,
    children,
    ...restProps
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  const wizardClass = classnames(styles.bv_wizard, {
    [`${customClass}`]: customClass,
  });

  const scrollToTop = () => {
    containerRef?.current?.scrollTo(0, 0);
  };

  return (
    <div id={id} className={wizardClass} {...restProps}>
      <WizardContext.Provider value={{ props, scrollToTop }}>
        <div ref={containerRef} className={styles.bv_wizard_container}>
          {wizardHeader}
          <div className={styles.bv_wizard_body}>
            {(currentStep > 1 || showBackBtnAlways) && iconBack}
            <div className={styles.bv_wizard_body_content}>{children}</div>
          </div>
        </div>
        {wizardFooter}
      </WizardContext.Provider>
    </div>
  );
}

Wizard.propTypes = {
  id: PropTypes.string.isRequired,
  showBackBtnAlways: PropTypes.bool.isRequired,
  currentStep: PropTypes.number.isRequired,
  wizardHeader: PropTypes.any,
  wizardFooter: PropTypes.any.isRequired,
  iconBack: PropTypes.any,
  customClass: PropTypes.string,
  children: PropTypes.any,
};

Wizard.defaultProps = {
  id: '',
  showBackBtnAlways: false,
};

export default Wizard;
