import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { isValid, parse } from 'date-fns';

import { Container, Row, Col } from '../Container';
import Text from '../Text';
import Input from '../Input';

import styles from './DateComponent.module.scss';

import { dateValidationsFeedback } from '@/utils/generalTexts';
import { regExpOnlyNumbers, currentYear, dayOfMonth, monthOfYear } from '@/utils/constants';
import { customDate, dateDay, dateMonth, dateYear } from '@/utils/validations';

type DateComponentT = {
  textTitle: string;
  day?: boolean;
  month?: boolean;
  year?: boolean;
  feedback?: string;
  onChange?: ({
    inputDate,
    generalHasError,
  }: {
    inputDate: unknown;
    generalHasError: boolean;
  }) => void;
  value?: string;
  customClass?: string;
  textVal?: string;
  validate: boolean;
  min: { years: number; days: number };
  max: { years: number; days: number };
};

function DateComponent({
  textTitle,
  day = true,
  month = true,
  year = true,
  feedback,
  onChange,
  value,
  customClass,
  textVal,
  validate,
  min,
  max,
}: DateComponentT) {
  const dayMonthYear = value && value.split('/');

  const formatTwoDigits = (value = '') => {
    if (value.length === 1 && value !== '0') {
      return `0${value}`;
    }

    return value;
  };

  const isValidDate = (value: string): boolean => {
    const parsedDate = parse(value, 'yyyy-MM-dd', new Date());
    const validated = isValid(parsedDate);

    return validated;
  };

  const [inputDate, setInputDate] = useState({
    day: dayMonthYear && dayMonthYear[0],
    month: dayMonthYear && dayMonthYear[1],
    year: dayMonthYear && dayMonthYear[2],
  });
  const dateFormat = `${inputDate.year}-${inputDate.month}-${inputDate.day}`;

  const [invalidDay, setInvalidDay] = useState(false);
  const [invalidMonth, setInvalidMonth] = useState(false);
  const [invalidYear, setInvalidYear] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);

  const [generalFeedback, setGeneralFeedback] = useState('');
  const [generalHasError, setGeneralError] = useState(false);

  const handlerDay = async (event: any) => {
    const { value: day } = event.target;
    setGeneralError(true);

    if (day <= dayOfMonth) {
      setInputDate({ ...inputDate, day });
    }
  };

  const handleBlurDay = async () => {
    setInputDate({ ...inputDate, day: formatTwoDigits(inputDate.day) });
    const day = Number(inputDate.day);
    try {
      await dateDay(textVal ?? '').validateAsync({ day });
      setInvalidDay(false);
    } catch (error) {
      setInvalidDay(true);
      setGeneralFeedback((error as Error).message);
    }
  };

  const handleBlurMonth = async () => {
    setInputDate({ ...inputDate, month: formatTwoDigits(inputDate.month) });
    const month = Number(inputDate.month);
    try {
      await dateMonth(textVal ?? '').validateAsync({ month });
      setInvalidMonth(false);
    } catch (error) {
      setInvalidMonth(true);
      setGeneralFeedback((error as Error).message);
    }
  };

  const handlerDate = async () => {
    if (!isValidDate(dateFormat)) {
      setGeneralError(true);
      setInvalidDate(true);

      setGeneralFeedback(dateValidationsFeedback.invalidDate);

      return;
    }

    try {
      await customDate({ min, max, feedback }).validateAsync({ date: dateFormat });
      setGeneralError(false);
      setInvalidDate(false);
      setGeneralFeedback('');
    } catch (error) {
      setGeneralError(true);
      setInvalidDate(true);

      setGeneralFeedback((error as Error).message);
    }
  };

  const handleBlurYear = async () => {
    const year = Number(inputDate.year);
    try {
      await dateYear(textVal ?? '', Number(new Date().getFullYear())).validateAsync({ year });
      setInvalidYear(false);
    } catch (error) {
      setInvalidYear(true);
      setGeneralFeedback((error as Error).message);
    }
  };

  const handlerMonth = async (event: any) => {
    const { value: month } = event.target;
    setGeneralError(true);

    if (month <= monthOfYear) {
      setInputDate({ ...inputDate, month });
    }
  };

  const handlerYear = async (event: any) => {
    const { value: year } = event.target;
    setGeneralError(true);

    if (year <= currentYear) {
      setInputDate({ ...inputDate, year });
    }
  };

  const handlerOnKeyPressVal = (event: any) => {
    if (!regExpOnlyNumbers.test(event.key)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (invalidDay || invalidMonth || invalidYear) {
      setGeneralError(true);
    }

    if (!invalidDay && !invalidMonth && !invalidYear) {
      setGeneralError(false);
    }
  }, [invalidDay, invalidMonth, invalidYear]);

  useEffect(() => {
    if (
      validate &&
      !invalidDay &&
      !invalidMonth &&
      !invalidYear &&
      !!inputDate.day &&
      inputDate.day?.length === 2 &&
      inputDate.month?.length === 2 &&
      inputDate.year?.length === 4
    ) {
      setGeneralError(false);

      handlerDate();
    }
  }, [inputDate, feedback, invalidYear, invalidMonth, invalidDay]);

  useEffect(() => {
    onChange && onChange({ inputDate, generalHasError });
  }, [inputDate, generalHasError]);

  const inputClass = classnames(styles.container_input, {
    [`${customClass}`]: customClass,
  });

  const sizeCol = !day || !month || !year ? 6 : 4;

  return (
    <Container className={styles.container}>
      <Row className={styles.container_title}>
        <Col className={styles.container_col_title}>
          <Text customClass={styles.text_title} text={textTitle} />
        </Col>
      </Row>

      <Row>
        {day && (
          <Col sm={sizeCol} lg={sizeCol} className={styles.padding_left_zero}>
            <Input
              id="dateDay"
              placeholder="Día"
              customClass={inputClass}
              onChange={handlerDay}
              value={inputDate.day}
              onKeyPress={handlerOnKeyPressVal}
              maxLength={2}
              onBlur={handleBlurDay}
              hasError={invalidDay || invalidDate}
              placeholderHint="DD"
            />
          </Col>
        )}

        {month && (
          <Col sm={sizeCol} lg={sizeCol} className={styles.padding_left_zero}>
            <Input
              id="dateMonth"
              placeholder="Mes"
              customClass={inputClass}
              onChange={handlerMonth}
              onBlur={handleBlurMonth}
              hasError={invalidMonth || invalidDate}
              value={inputDate.month}
              onKeyPress={handlerOnKeyPressVal}
              maxLength={2}
              placeholderHint="MM"
            />
          </Col>
        )}

        {year && (
          <Col sm={sizeCol} lg={sizeCol} className={styles.padding_right_zero}>
            <Input
              id="dateYear"
              placeholder="Año"
              customClass={inputClass}
              onChange={handlerYear}
              onBlur={handleBlurYear}
              hasError={invalidYear || invalidDate}
              value={inputDate.year}
              onKeyPress={handlerOnKeyPressVal}
              maxLength={4}
              placeholderHint="AAAA"
            />
          </Col>
        )}
      </Row>
      <div>
        {generalHasError && <p className={styles.feedback_message}>{`${generalFeedback}`}</p>}
      </div>
    </Container>
  );
}

export default DateComponent;
