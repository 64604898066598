import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import styles from './RadioButton.module.scss';

export function RadioButtonGroup({
  id,
  direction,
  onChange,
  children,
  customClass,
}: InferProps<typeof RadioButtonGroup.propTypes>) {
  const radioButtonGroupClass = classnames(styles.radio_button_group, {
    [styles[`radio_button_group_${direction}`]]: direction,
    [`${customClass}`]: customClass,
  });

  const onChangeHandler = (event: any) => {
    const { value } = event.target;

    onChange && onChange(value);
  };

  return (
    <div id={id} onChange={onChangeHandler} className={radioButtonGroupClass}>
      {children}
    </div>
  );
}

RadioButtonGroup.propTypes = {
  id: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['row', 'column']).isRequired,
  onChange: PropTypes.func,
  children: PropTypes.any,
  customClass: PropTypes.string,
};

RadioButtonGroup.defaultProps = {
  id: '',
  direction: 'row',
};

type TRadioButtonProps = {
  id?: string;
  name: string;
  value: any;
  text?: string;
  disabled?: boolean;
  checked: boolean;
  onChange?: (value: any) => void;
  customClass?: string;
};

function RadioButton({
  id = '',
  name,
  value,
  text,
  disabled = false,
  checked = false,
  onChange,
  customClass,
}: TRadioButtonProps) {
  const radioButtonClass = classnames(styles.radio_button, {
    [`${customClass}`]: customClass,
  });

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    onChange && onChange(checked ? value : undefined);
  };

  return (
    <label id={id} className={radioButtonClass}>
      <input
        name={name}
        type="radio"
        checked={checked}
        onChange={onChangeHandler}
        value={value}
        disabled={disabled}
      />
      <span className={styles.radio_button_checkmark} />
      {text && <p className={styles.radio_button_text}>{text}</p>}
    </label>
  );
}

export default RadioButton;
