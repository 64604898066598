import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import styles from './LinearProgressIndicator.module.scss';

LinearProgressIndicatorText.propTypes = {
  steps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  customClass: PropTypes.string,
  smallSize: PropTypes.bool,
};

export function LinearProgressIndicatorText({
  steps,
  currentStep,
  customClass,
  smallSize = false,
}: InferProps<typeof LinearProgressIndicatorText.propTypes>) {
  const indicatorTextClass = classnames(styles.linear_progress_indicator_text, {
    [`${customClass}`]: customClass,
  });

  const remainingSteps = steps - currentStep + 1;

  return smallSize ? (
    <span className={indicatorTextClass}>
      Paso {currentStep} de {steps}
    </span>
  ) : (
    <span className={indicatorTextClass}>
      Queda{`${remainingSteps > 1 ? 'n' : ''}`} {remainingSteps} paso
      {`${remainingSteps > 1 ? 's' : ''}`} por completar
    </span>
  );
}

function LinearProgressIndicator({
  steps,
  currentStep,
  customClass,
}: InferProps<typeof LinearProgressIndicator.propTypes>) {
  const indicatorRef = useRef<HTMLDivElement>(null);
  const [progressWidth, setProgressWidth] = useState(0);

  const indicatorClass = classnames(styles.linear_progress_indicator, {
    [`${customClass}`]: customClass,
  });

  useEffect(() => {
    const progressRatio = ((currentStep - 1) / steps) * 100;

    setProgressWidth(progressRatio);
  }, [currentStep, steps]);

  return (
    <div className={indicatorClass}>
      <div
        style={{ width: `${progressWidth}%` }}
        ref={indicatorRef}
        className={styles.linear_progress_indicator_progress}
      />
    </div>
  );
}

LinearProgressIndicator.propTypes = {
  steps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  customClass: PropTypes.string,
};

export default LinearProgressIndicator;
