import React, { useState, useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import styles from './Checkbox.module.scss';

function Checkbox({
  id,
  text,
  disabled,
  checked,
  onChange,
  customClass,
}: InferProps<typeof Checkbox.propTypes>) {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const checkboxClass = classnames(styles.checkbox, {
    [`${customClass}`]: customClass,
  });

  const onChangeHandler = (event: any) => {
    const { checked: newCondition } = event.target;

    onChange && onChange(newCondition);
    setIsChecked(newCondition);
  };

  useEffect(() => {
    onChangeHandler({ target: { checked } });
  }, [checked]);

  return (
    <label id={id} className={checkboxClass}>
      <input type="checkbox" checked={isChecked} onChange={onChangeHandler} disabled={disabled} />
      <span className={styles.checkbox_checkmark} />
      {text && <p className={styles.checkbox_text}>{text}</p>}
    </label>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  customClass: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
};

export default Checkbox;
