import React, { useContext, useEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Button } from '@BICE_Vida/components';
import classnames from 'classnames';

import styles from './Wizard.module.scss';

import { WizardContext } from './Wizard';

import { breakPointMd } from '../../utils/constants';

function WizardFooter({
  id,
  backBtn,
  nextBtn,
  customClass,
  children,
}: InferProps<typeof WizardFooter.propTypes>) {
  const wizardFather = useContext(WizardContext);
  const [btnSize, setBtnSize] = useState<any>();

  const wizardFooterClass = classnames(styles.bv_wizard_footer, {
    [`${customClass}`]: customClass,
  });

  const onClickNextHandler = (event: any) => {
    wizardFather.scrollToTop();

    nextBtn.onClick(event);
  };

  const onResizeFooter = () => setBtnSize(window.innerWidth > breakPointMd ? 'large' : 'medium');

  useEffect(() => {
    window.addEventListener('resize', onResizeFooter);
    onResizeFooter();

    return () => {
      window.removeEventListener('resize', onResizeFooter);
    };
  }, []);

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') !nextBtn.disabled && onClickNextHandler(event);
  };

  useEffect(() => {
    window.addEventListener('keypress', onKeyPress);

    return () => window.removeEventListener('keypress', onKeyPress);
  }, [nextBtn.disabled, onKeyPress]);

  return (
    <div id={id} className={wizardFooterClass}>
      <div className={styles.bv_wizard_footer_content}>
        <div className={styles.bv_wizard_footer_additional_content}>{children}</div>
        <div className={styles.bv_wizard_btn_group}>
          {(wizardFather.props.currentStep > 0 || wizardFather.props.showBackBtnAlways) && (
            <Button
              id={backBtn.id}
              text={backBtn.text}
              size={btnSize}
              variant="text"
              onClick={backBtn.onClick}
              disabled={backBtn.disabled}
              className={styles.bv_wizard_btn_back_desktop}
            />
          )}
          <Button
            id={nextBtn.id}
            text={nextBtn.text}
            size={btnSize}
            onClick={onClickNextHandler}
            disabled={nextBtn.disabled}
            loading={nextBtn.loading}
            className={styles.bv_wizard_btn_ok}
          />
        </div>
      </div>
    </div>
  );
}

const wizardButtonProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
});

WizardFooter.propTypes = {
  id: PropTypes.string.isRequired,
  nextBtn: wizardButtonProps.isRequired,
  backBtn: wizardButtonProps.isRequired,
  customClass: PropTypes.string,
  children: PropTypes.any,
};

WizardFooter.defaultProps = {
  id: '',
  backBtn: {
    id: '',
    text: '',
    loading: false,
    onClick: () => {},
    disabled: false,
  },
};

export default WizardFooter;
