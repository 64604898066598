import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';

import { Button } from '@BICE_Vida/components';

import ReactModal from 'react-modal';
import Text from '../Text';
import SvgImg from '../SvgImg';
import Icon, { IconBg } from '../Icon';
import IconAlt from '../IconAlt';

import styles from './Modal.module.scss';

function Modal({
  id,
  isOpen,
  img,
  textTitle,
  textDescription,
  fullScreen,
  button,
  children,
  close,
  type,
  getRef,
  closeIcon,
  modalClass,
  overlayClass,
  closeOnOverlay,
  closeOnIcon,
  wide,
  fromTop,
  isHalf,
}: ModalPropsT) {
  const modalContainerRef = useRef<HTMLDivElement>(null);

  const [overlayClassShow, setOverlayClassShow] = useState(styles.modal_hide);
  const overlayClasses = [styles.modal_overlay];
  const modalClasses = classnames(styles.modal_modal, {
    [styles.modal_half_modal]: isHalf,
    [styles.modal_fullscreen]: fullScreen,
    [styles[`modal_${type}`]]: !!type,
    [styles.with_close_icon]: !!closeOnIcon && !!close,
    [modalClass ?? '']: !!modalClass,
    [styles.modal_from_top]: fromTop,
  });

  !!overlayClass && overlayClasses.push(overlayClass);
  fullScreen && overlayClasses.push(styles.modal_fullscreen);

  const textDescriptionClasses = classnames(styles.modal_description, {
    [styles.modal_wide]: wide,
  });

  const imgSize = type === 'half_modal' ? 'large' : 'medium';

  const onRequestClose = closeOnOverlay && !!close ? close : undefined;

  useEffect(() => {
    let timeOut: any;

    if (isOpen) {
      document.documentElement.style.overflow = 'hidden';

      timeOut = setTimeout(() => {
        setOverlayClassShow(styles.modal_show);
        getRef(modalContainerRef, isOpen);
      }, 50);
    } else {
      document.documentElement.style.overflow = 'auto';

      setOverlayClassShow(styles.modal_hide);
      getRef(modalContainerRef, isOpen);
    }

    return () => {
      clearTimeout(timeOut);
    };
  }, [isOpen]);

  return (
    <ReactModal
      isOpen={isOpen}
      closeTimeoutMS={300}
      className={modalClasses}
      ariaHideApp={false}
      overlayClassName={`${overlayClasses.join(' ')} ${overlayClassShow}`}
      onRequestClose={onRequestClose}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={closeOnOverlay}
      shouldFocusAfterRender={false}
    >
      <div ref={modalContainerRef} id={id} className={styles.modal_container}>
        <div className={styles.modal_body}>
          {closeOnIcon && !!close && (
            <div className={styles.modal_icon_container}>
              <IconAlt title="Cerrar" position="bottom_right">
                <IconBg shape="circle" customClass={styles[`modal_icon_bg_${closeIcon}`]}>
                  <Icon
                    name="close"
                    size="medium"
                    onClick={close}
                    customClass={styles[`modal_icon_${closeIcon}`]}
                  />
                </IconBg>
              </IconAlt>
            </div>
          )}
          {!!img && (
            <div className={styles.modal_img}>
              <SvgImg name={img} alt="bicevida modal icon" size={imgSize} />
            </div>
          )}
          <div className={styles.modal_content}>
            {!!textTitle && <Text text={textTitle} customClass={styles.modal_title} />}
            {!!textDescription && (
              <Text text={textDescription} customClass={textDescriptionClasses} />
            )}
            <div>{children}</div>
          </div>
        </div>
        {!!button && (
          <div className={styles.modal_btn_container}>
            <Button
              id={button.id}
              text={button.text}
              onClick={button.click}
              disabled={button.disabled}
            />
          </div>
        )}
      </div>
    </ReactModal>
  );
}

type ModalPropsT = {
  id: string;
  isOpen: boolean;
  img?: React.ComponentProps<typeof SvgImg>['name'];
  textTitle?: string;
  textDescription?: string;
  fullScreen: boolean;
  button?: {
    id: string;
    text: string;
    click: React.MouseEventHandler;
    disabled: boolean;
  };
  children?: React.ReactNode;
  close?: React.MouseEventHandler;
  type: 'feedback' | 'half_modal';
  getRef: (ref: React.RefObject<HTMLDivElement>, isOpen: boolean) => void;
  closeIcon: string;
  modalClass?: string;
  overlayClass?: string;
  closeOnOverlay: boolean;
  closeOnIcon: boolean;
  wide?: boolean;
  fromTop?: boolean;
  isHalf?: boolean;
};

Modal.defaultProps = {
  id: '',
  isOpen: false,
  fullScreen: false,
  type: 'feedback',
  getRef: () => {},
  closeIcon: 'default',
  closeOnOverlay: false,
  closeOnIcon: true,
  wide: false,
  fromTop: false,
};

export default Modal;
