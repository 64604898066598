import React, { useCallback, useRef, useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import styles from './Input.module.scss';

import componentsId from '../../utils/componentsId';
import { breakPointSm } from '../../utils/constants';
import { useScroll } from '../../customHooks';

function Input({
  id,
  placeholder,
  type,
  value,
  onChange,
  onBlur,
  customClass,
  hasError,
  feedback,
  phone,
  onKeyPress,
  maxLength,
  classContainer,
  pattern,
  inputMode,
  autoFocus,
  autoScroll,
  disabledOut,
  placeholderHint,
}: InferProps<typeof Input.propTypes>) {
  const scrollTimeOut = useRef<any>();
  const inputRef = useRef<any>();

  const containerClass = classnames(styles.input_container, {
    [`${customClass}`]: customClass,
    [`${classContainer}`]: classContainer,
    [styles.container_disabled]: disabledOut,
  });

  const inputClass = classnames(styles.input, {
    [`${customClass}`]: customClass,
    [styles.input_error]: hasError,
    [styles.input_phone]: phone,
    [styles.input_disabled]: disabledOut,
  });

  const [scrollToCenter, inputContainerRef] = useScroll('center');

  const onFocusInput = () => {
    if (!navigator.userAgent.match(/iPhone|iPad/) && !!inputContainerRef) {
      scrollTimeOut.current = setTimeout(scrollToCenter, 300);
    }
  };

  const callbackRef = useCallback((inputElement: HTMLInputElement) => {
    if (!!inputElement) {
      const isDesktop = window.innerWidth > breakPointSm;

      inputRef.current = inputElement;

      autoScroll && inputRef.current.addEventListener('focus', onFocusInput);
      autoScroll && inputRef.current.addEventListener('click', onFocusInput);

      autoFocus && isDesktop && inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    return () => {
      autoScroll && inputRef?.current?.removeEventListener('focus', onFocusInput);
      autoScroll && inputRef?.current?.removeEventListener('click', onFocusInput);
      clearTimeout(scrollTimeOut.current);
    };
  }, []);

  return (
    <div ref={inputContainerRef} className={containerClass}>
      <input
        ref={callbackRef}
        id={id}
        type={type || 'text'}
        value={value || ''}
        onChange={onChange}
        onBlur={onBlur}
        className={inputClass}
        autoComplete="off"
        required
        onKeyPress={onKeyPress}
        maxLength={maxLength}
        pattern={pattern}
        inputMode={inputMode}
      />

      {phone && (
        <span id={componentsId.formHirePlaceholderPhone} className={styles.placeholder_permanent}>
          +56
        </span>
      )}

      {phone && value === '' && <span className={styles.placeholder_secundary}>9 12345678</span>}

      <span className={styles.floating_label}>{placeholder}</span>
      {!value && placeholderHint && (
        <span className={styles.placeholderHint}>{placeholderHint}</span>
      )}
      {hasError && feedback && <span className={styles.input_feedback}>{feedback}</span>}
    </div>
  );
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string.isRequired]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  phone: PropTypes.bool,
  customClass: PropTypes.string,
  hasError: PropTypes.any,
  feedback: PropTypes.any,
  maxLength: PropTypes.number.isRequired,
  classContainer: PropTypes.string,
  pattern: PropTypes.any,
  inputMode: PropTypes.any.isRequired,
  autoFocus: PropTypes.bool.isRequired,
  autoScroll: PropTypes.bool.isRequired,
  disabledOut: PropTypes.bool.isRequired,
  placeholderHint: PropTypes.string,
};

Input.defaultProps = {
  hasError: false,
  maxLength: 100,
  onBlur: () => {},
  onChange: () => {},
  onKeyPress: () => {},
  pattern: undefined,
  inputMode: 'text',
  value: '',
  autoFocus: false,
  autoScroll: false,
  disabledOut: false,
  placeholderHint: '',
};

export default Input;
